import { Elm } from "./src/Main.elm";
import "./scss/style.scss";
import { readFileSync } from "fs";

if (module.hot) {
  module.hot.dispose(() => {
    window.location.reload();
  });
}

const flags = {};

const app = Elm.Main.init({ flags });

if (process.env.NODE_ENV === "production") {
    // Fathom - stats without tracking or storing personal data of your users
    (function(f, a, t, h, o, m) {
      a[h] =
        a[h] ||
        function() {
          (a[h].q = a[h].q || []).push(arguments);
        };
      (o = f.createElement("script")), (m = f.getElementsByTagName("script")[0]);
      o.async = 1;
      o.src = t;
      o.id = "fathom-script";
      m.parentNode.insertBefore(o, m);
    })(document, window, "//cdn.usefathom.com/tracker.js", "fathom");
    fathom("set", "siteId", "PRCCKGTT");
    fathom("trackPageview");

    app.ports.logPath.subscribe(function(path) {
      window.fathom("trackPageview", { path });
    });
}